.Footer{
    padding: 50px 0;
    background: rgb(151,80,92);

    color: rgb(222, 222, 222);
}

.Footer__container{
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3,1fr);
}
.Footer__container div{
    display: flex;
    justify-content: center;
    align-items: center;
}

li svg{
    padding: 8px;
    background: white;
    border-radius: 50%;
    margin: 0 10px;

}
li svg:hover{
    background: rgb(89, 89, 89);
    cursor: pointer;
    transition: all ease 0.3s;
}

li{
    margin: 5px 0;
    font-size: 14px;
}



@media (max-width: 767px) {
    .Footer{
        padding: 50px 0;
        background: rgb(151,80,92);
        color: rgb(222, 222, 222);
    }

    .Footer__container{
        grid-template-columns: repeat(1,1fr);
        gap: 40px;
        text-align: center;
    }
      }