.Safety {
    background: rgb(151, 80, 92);
    height: 100%;
    padding: 50px 0;
    color: white;


}

.Safety__container {
    max-width: 1000px;
    width: 100%;
    height: 100vh;
    margin: 0 auto;
}

.Safety__container p {
    display: flex;
    align-items: center;
    font-weight: 300;
    font-size: 20px;
    letter-spacing: 1px;
    margin: 10px 0;
}

.Safety__container p:nth-child(1) {
    font-weight: 700;
    text-align: center;
    margin-bottom: 40px;
}

@media (max-width: 767px) {

    .Safety {
        padding: 50px 15px;

    }

    .Safety__container {
        height: 100%;
    }

    .Safety__container p {
        margin: 20px 0;
    }

}