.Btn{
   max-width: 250px;
   max-height: 45px;
   width: 100%;
   height: 100%;
    margin: 7px 0;
    background: rgb(255,90,48);
    border-radius: 12px;
    border: 1px solid rgb(238, 238, 238);
    outline: none;
    cursor: pointer;
    color:rgb(238, 238, 238) ;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding: 10px 8px;
    font-size: 12px;
}
.Btn:hover{
    background: rgb(246, 75, 32);
}