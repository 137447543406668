*{
  padding: 0;
  margin: 0;
}
li{
  list-style: none;
}

a{
  color: red;
}