.Info {
    background: rgb(151, 80, 92);
    height: 100%;
    padding: 50px 0;
    color: white;


}

.Info__container {
    max-width: 1000px;
    width: 100%;
    height: 100vh;
    margin: 0 auto;
}
