.AbautsSkills{
    background: rgb(151,80,92);
    width: 100%;
    height: 100%;
padding: 50px 0;
color: white;
    
}
.AbautsSkills__container{
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
}
.AbautsSkills h1{
  text-align: center;

}

.AbautsSkills p{
    padding: 5px 20px;
}

.AbautsSkills img {
    margin: 50px  20px;
    width: 200px;
    height: 200px;
    object-fit: cover;

}

.AbautsSkills__container div{
  display: flex;
  justify-content: center;
}



@media (max-width: 767px) {
  .AbautsSkills p{
 text-align: left;
}


    }