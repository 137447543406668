.Modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;

}

.Modal.active {
    opacity: 1;
    visibility: visible;
    transition: 0.5s
}

.ModalContent {
    background: rgb(236, 208, 212);
    width: 100%;
    height: auto;
    max-width: 300px;
    max-height: 100%;
    border-radius: 16px;
    padding: 10px;
}
.ModalHader {
    display: flex;
    justify-content: end;
}
.ModalHader svg:hover {
   color: rgb(255,90,48);
   cursor: pointer;
}
.ModalHader{
    height: 30px;
    border-bottom:2px solid rgb(255,90,48) ;
}
.ModalBody form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

form input{
    margin: 10px 0;
    padding: 5px 8px;
    width: 100%;
    height: 20px;
    border-radius: 6px;
    border: none;
    outline: none;
}

.customCheckBox{
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 1px solid red;
    border-radius: 4px;
    vertical-align: sub;
    margin-right: 10px;
    position: relative;
    cursor: pointer;
}

.customCheckBox::before{
  content: "";
  display: inline-block;
  width: 13px;
  height: 13px;
  background: url(../images/check.svg);
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%)scale(0);
  transition: 0.2s ease-in; 
}

.realCheckBox:checked + .customCheckBox::before{
    transform: translate(-50%,-50%)scale(1);
}
.realCheckBox{
    width: 0;
    position: absolute;
    z-index: -1;
}

.ModalFooter form{
    border-top: 2px solid rgb(255,90,48);
    padding: 15px;

}
.ModalFooter form div{
 display: flex;
 margin-top: 15px;

}

.ModalFooter{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}