.Information{
    background: rgb(151,80,92);
    width: 100%;
    height: 100%;
padding: 50px 0;
color: white;
}

.Information__container{
    max-width: 1000px;
    height: 100vh;
    margin: 0 auto;
    padding: 15px;
    display: flex;
    flex-direction: column;
}
.Information__container span{
 margin: 10px 0;
}