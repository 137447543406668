.About {
    background: rgb(80, 36, 60);
    color: rgb(217, 217, 217);
    padding: 30px 0 100px;
}

.About__container {
    max-width: 1000px;
    text-align: center;
    margin: 0 auto;
}
.About__container h1{
  font-size: 50px;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: rgb(217, 217, 217);
  font-weight: 500;
}
.About__container p{
  font-size: 18px;
  max-width: 600px;
  margin: 5px auto;
}

.About__container svg{

  margin: 0 auto 0px;
}

.Anout__block{
  margin-top: 55px;
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap: 20px;
}
.Anout__block div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;
  border-radius: 12px;
}

.Anout__block img{
  width: 100%;
  border-radius: 12px 12px 0 0;
}
.Anout__block span{
  padding: 0 5px;
  font-size: 13px;
  font-weight: 300;
  margin: 10px 0;
}
.Anout__block h2{
  margin: 10px 0;
}

 a{
  color: rgb(255,90,48);
}

@media (max-width: 767px) {
  .About {
padding: 50px 15px ;
}

  .About__container h1{
    font-size: 35px;
    font-weight: 600;
  }

  .About__container p{
    font-size: 14px;
    max-width: 600px;
    margin: 10px auto 30px;
  }

  .Anout__block{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    gap: 20px;
  }

  .Anout__block span{
    font-size: 16px;
    font-weight: 400;

  }
    }