.Header{
    background: rgb(124,65,97);
    padding: 50px 0;
}

.Header__container{
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
 
}


.Header__left{
    display: flex;
    flex-direction: column;   
    align-items: start;
    justify-content: center;
    color: rgb(217, 217, 217);
}


.Header__rigth{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
}
.Header__logo__title{
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.Header__logo__title h2{
    font-size: 50px;
    font-weight: 700;
    margin: 5px 0 20px;
    text-transform:uppercase;
}
.Header h3{
    font-size: 25px;
    font-weight: 500;
    margin: 10px 0 5px;
    text-transform:uppercase;
}

.Header__left p{
    font-size: 17px;
    font-weight: 300;
    max-width: 350px;
    width: 100%;
    margin-bottom: 20px;
}


@media (max-width: 767px) {
    .Header{
        padding: 15px;
    }

    .Header__container{
        grid-template-columns: 1fr;

    }

    .Header__logo__title h2{
        font-size: 35px;
        margin: 5px 0 ;
    }

    .Header h3{
        font-size: 20px;
    }

    .Header__left{
        text-align: center;
        display: flex;
        flex-direction: column;   
        align-items: center;
        justify-content: center;

    }
    .Header__rigth{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
  }