.MainPruf {
    padding: 80px;
    height: 100%;
    background: url(https://klike.net/uploads/posts/2023-06/1685937754_3-33.jpg);
    background-size: cover;
    background-position: bottom;
    display: flex;
    align-items: center;
    justify-content: center
}

.MainPruf__block{
    display: grid;
    grid-template-columns: repeat(2,400px);
    gap: 60px;
    justify-content: center;
}
.MainPruf__block div{
    text-align: center;
    background: white;
    height: 100%;
    padding: 8px 0;
}

.MainPruf__block img{
    width: 100%;
}
.MainPruf__block h3{
    padding: 15px 0;
}
.MainPruf__block p{
    text-align: center;
    padding: 15px 0;
}


@media (max-width: 767px) {
    .MainPruf {
        padding: 50px 15px;
        background: url(https://klike.net/uploads/posts/2023-06/1685937754_3-33.jpg);
        background-size: cover;
        background-position: right;
 
    }
    
    .MainPruf__block{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        gap: 40px;
        justify-content: center;
    }
      }