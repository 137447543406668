.Abauts {
    background: rgb(151, 80, 92);
    width: 100%;
    height: 100%;
    color: white;
}

.Abauts h1 {
    text-align: center;
    padding: 50px 0;
}

.Abauts__container {
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;

}

img {
    max-width: 500px;
    width: 100%;
    border-radius: 16px;
    margin: 0 auto;
}

.Abauts__container h3 {
    margin: 5px 0 10px;
}


@media (max-width: 767px) {
    .Abauts__container {
        height: 100%;
        grid-template-columns: repeat(1, 1fr);
        padding: 15px;
    }
}